<template>
  <div v-if="inIt">
    <div class="row mt-2 mb-2">
      <div class="col-12 col-xl-10 col-sm-11">
        <b-form-group
          label="บัญชีธนาคาร"
          label-for="h-first-name"
          label-cols-md="3"
          :style="sizepagee !== 'xs' ? 'text-align: end' : ''"
          class="fromlabel mb-1"
        >
          <b-form-select
            v-model="inputsPay.datasBank.value"
            :options="optionAccount"
          />
        </b-form-group>
        <b-form-group
          label="ชื่อบัญชีธนาคาร"
          label-for="h-first-name"
          label-cols-md="3"
          :style="sizepagee !== 'xs' ? 'text-align: end' : ''"
          class="fromlabel mb-1"
        >
          <b-form-input
            v-model="inputsPay.datasBank.name"
            readonly
            class="mb-1"
            style="    background-color: white;"
          />
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="inputsPay.datasBank.bankNumber"
              readonly
              style="    background-color: white;"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="ClipboardIcon"
                style="width: 16px;cursor:pointer"
                @click="Clipboard(inputsPay.datasBank.bankNumber)"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="จำนวนเงิน"
          label-for="h-first-name"
          label-cols-md="3"
          :style="sizepagee !== 'xs' ? 'text-align: end' : ''"
          class="fromlabel mb-1"
        >
          <b-form-input
            v-model="sumPriceCartForfmat"
            readonly
            class="mb-1"
          />
        </b-form-group>
        <b-form-group
          label="ช่องทางการฝากเงิน"
          label-for="h-first-name"
          label-cols-md="3"
          :style="sizepagee !== 'xs' ? 'text-align: end' : ''"
          class="fromlabel mb-1"
        >
          <b-form-select
            v-model="inputsPay.typePay"
            :options="typePay"
          />
        </b-form-group>
        <validation-observer ref="bankcustomerRules">
          <validation-provider
            #default="{ errors }"
            name="bankcustomer"
            rules="required"
          >
            <b-form-group
              v-show="
                inputsPay.typePay === 'd' || inputsPay.typePay === 'c' || inputsPay.typePay === 'r'
                  ? false
                  : true
              "
              label="รายละเอียดบัญชี"
              label-for="h-first-name"
              label-cols-md="3"
              :style="sizepagee !== 'xs' ? 'text-align: end' : ''"
              class="fromlabel mb-1"
            >
              <div class="d-flex">
                <b-form-select
                  v-model="inputsPay.idBank"
                  class="mr-1"
                  :options="opptionBankCustomer"
                  :state="errors.length > 0 ? false : null"
                />
                <b-button
                  variant="outline-primary"
                  class="rounded text-center"
                  style="height:auto;padding: 0px;    width: 40px !important;"
                  @click="onClickAddAccount"
                >
                  <b-icon-plus font-scale="1.6" />
                </b-button>
              </div>
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >กรุณาเลือกรายละเอียดบัญชี</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
        <b-modal
          v-model="IsopenModalAddAccount"
          hide-footer
          header-bg-variant="primary"
          size="sm"
          title="เพิ่มรายละเอียดธนาคารของสมาชิก"
          centered
        >
          <div class="row mt-1">
            <div class="col-12">
              <b-form-group
                label="ธนาคาร"
                label-for="h-first-name"
                label-cols-md="4"
                class="fromlabel mb-1"
              >
                <b-form-select
                  v-model="inputAddBank.branchName"
                  :options="opptionBank"
                />
              </b-form-group>
              <b-form-group
                label="ชื่อธนาคาร"
                label-for="h-first-name"
                label-cols-md="4"
                class="fromlabel mb-1"
              >
                <b-form-input v-model="inputAddBank.name" />
              </b-form-group>
              <b-form-group
                label="เลขที่บัญชี"
                label-for="h-first-name"
                label-cols-md="4"
                class="fromlabel mb-1"
              >
                <b-form-input
                  v-model="inputAddBank.branchCode"
                  placeholder="ระบุหมายเลขบัญชี"
                />
              </b-form-group>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-2 mb-2">
            <b-button
              class="rounded text-center"
              style="height:40px;padding: 0px;    width: 90px !important;"
              variant="primary"
              @click="onClickCommitAccount"
            >
              <span>ตกลง</span>
            </b-button>
            <b-button
              class="rounded text-center ml-1"
              style="height:40px;padding: 0px;    width: 90px !important;"
              variant="secondary"
              @click="
                () => {
                  IsopenModalAddAccount = false;
                }
              "
            >
              <span>ยกเลิก</span>
            </b-button>
          </div>
        </b-modal>
      </div>
      <div
        v-if="inputsPay.typePay !== 'r'"
        class="col-md-9  offset-md-3 d-flex mt-1 mb-2"
      >
        <b-form-file
          id="fileUpload"
          v-model="inputsPay.file"
          class="mt-1"
          style="display:none"
        />
        <b-button
          class="rounded text-center"
          style="height:40px;   width: auto !important;"
          variant="primary"
          @click="chooseFiles"
        >
          <span>อัพโหลดใบเสร็จ</span>
        </b-button>
      </div>
      <div class="col-md-9 offset-md-3">
        <b-img
          v-show="inputsPay.pic !== '' ? true : false"
          thumbnail
          fluid
          :src="inputsPay.pic"
          style="max-width:250px;width:100%"
          @error="setAltImg"
        />
      </div>
    </div>
    <div class="d-flex justify-content-center justify-content-sm-end mt-1 mb-2">
      <b-button
        class="rounded text-center mr-1"
        style="height:40px;   width: auto !important;"
        variant="primary"
        @click="checkDataPayment"
      >
        <span>ยืนยัน</span>
      </b-button>
      <b-button
        class="rounded text-center"
        style="height:40px;padding: 0px;    width: 90px !important;"
        variant="secondary"
        @click="backpage"
      >
        <span>ย้อนกลับ</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BCard, BCardBody, BBadge, BFormCheckbox, BFormInput, BInputGroupPrepend, BInputGroup, BImg,
  BInputGroupAppend, BIconPlus, BFormGroup, BFormTextarea, BFormSelect, BFormFile
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import data_bank from '@/data-json/bank.json'
import PlaceHolder from '@/assets/images/production/slip.png'

export default defineComponent({
  name: 'order_payment',
  components: {
    BButton,
    BCard,
    BCardBody,
    BBadge,
    BFormCheckbox,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BImg,
    BInputGroupAppend,
    BIconPlus,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormFile,
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    sizepagee () {
      return this.$store.getters['app/currentBreakPoint']
    }
  },
  watch: {
    'inputsPay.file': {
      handler (e) {
        if (!e) return
        const newfile = e
        this.inputsPay.pic = URL.createObjectURL(newfile)
      }
    },
    'inputsPay.typePay': {
      handler (val) {
        if (val === 'c' || val === 'd' || val === 'r') {
          this.inputsPay.idBank = ''
          this.inputsPay.file = null
          this.inputsPay.pic = ''
        }
      }
    },
    'inputsPay.datasBank.value': {
      handler (val) {
        const data = this.optionAccount.filter(x => x.value === val)
        this.inputsPay.datasBank.name = data[0].name
        this.inputsPay.datasBank.bankNumber = data[0].bankNumber
        this.inputsPay.datasBank.bankName = data[0].bankName
      }
    }
  },
  methods: {
    setAltImg (e) {
      if (this.checkpic === false) {
        e.target.src = this.$baseURL + this.inputsPay.pic
        this.checkpic = true
      } else {
        e.target.src = PlaceHolder
        this.checkpic = false
      }
    },
    showAlert (text) {
      this.$swal({
        title: text,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    checkDataPayment () {
      if (this.inputsPay.typePay !== 'd' && this.inputsPay.typePay !== 'c' && this.inputsPay.typePay !== 'r') {
        this.$refs.bankcustomerRules.validate().then(success => {
          if (success) {
            if (this.type === 'add') this.confirmPayment(); else this.confirmUpdatePayment()
          }
        })
      } else if (this.type === 'add') this.confirmPayment(); else this.confirmUpdatePayment()
    },
    confirmUpdatePayment () {
      if (this.inputsPay.file === null) {
        this.showAlert('โปรดอัพโหลดใบเสร็จ')
        return
      }
      const setAPITransection = {
        transectionId: this.dataPayment._id,
        orderId: this.idOrder,
        amount: parseFloat(this.sumPriceCartForfmat.replace(',', '')),
        type: this.inputsPay.typePay,
        bankAccount: {
          name: '',
          bankNumber: '',
          bankName: '',
          bankDetail: ''
        },
        bankaccountId: this.inputsPay.datasBank.value,
        file: this.inputsPay.file
      }
      const dt = this.opptionBankCustomer.filter(x => x.value === this.inputsPay.idBank)
      if (this.inputsPay.typePay === 'd' || this.inputsPay.typePay === 'c') {
        setAPITransection.bankAccount = null
      } else {
        setAPITransection.bankAccount = {
          name: dt[0].name,
          bankNumber: dt[0].bankNumber,
          bankName: dt[0].bankName,
          bankDetail: ''
        }
      }
      const formData = new FormData()
      formData.append('slip', this.inputsPay.file)
      formData.append('datas', JSON.stringify(setAPITransection))
      this.$store.dispatch('order/updateOrderPayment', formData).then(result => {
        if (result.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: result.message
            }
          })
          this.$router.go(-1)
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: result.message
            }
          })
        }
      })
    },
    confirmPayment () {
      if (this.inputsPay.file === null && this.inputsPay.typePay !== 'r') {
        this.showAlert('โปรดอัพโหลดใบเสร็จ')
        return
      }
      const setAPITransection = {
        orderId: this.idOrder,
        amount: parseFloat(this.sumPriceCartForfmat.replace(',', '')),
        type: this.inputsPay.typePay,
        bankAccount: {
          name: '',
          bankNumber: '',
          bankName: '',
          bankDetail: ''
        },
        bankaccountId: this.inputsPay.datasBank.value,
        file: this.inputsPay.file
      }
      const dt = this.opptionBankCustomer.filter(x => x.value === this.inputsPay.idBank)
      if (this.inputsPay.typePay === 'd' || this.inputsPay.typePay === 'c' || this.inputsPay.typePay === 'r') {
        setAPITransection.bankAccount = null
      } else {
        setAPITransection.bankAccount = {
          name: dt[0].name,
          bankNumber: dt[0].bankNumber,
          bankName: dt[0].bankName,
          bankDetail: ''
        }
      }
      const formData = new FormData()
      formData.append('slip', this.inputsPay.file)
      formData.append('datas', JSON.stringify(setAPITransection))
      this.$store.dispatch('order/addOrderPayment', formData).then(result => {
        if (result.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: result.message
            }
          })
          this.$router.go(-1)
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: result.message
            }
          })
        }
      })
    },
    backpage () {
      this.$router.go(-1)
    },
    onClickAddAccount () {
      this.IsopenModalAddAccount = true
    },
    makeToast (variant = 'primary') {
      this.$bvToast.toast('Copied!', {
        variant,
        solid: false
      })
    },
    Clipboard (textToCopy) {
      const tmpTextField = document.createElement('textarea')
      tmpTextField.textContent = textToCopy
      tmpTextField.setAttribute('style', 'position:absolute; right:200%;')
      document.body.appendChild(tmpTextField)
      tmpTextField.select()
      tmpTextField.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand('copy')
      tmpTextField.remove()
      this.makeToast()
    },
    chooseFiles () {
      document.getElementById('fileUpload').click()
    },
    async loader () {
      await this.loadSystemAccount()
      await this.loadBank()
      await this.onLoadOrderId()
      this.inIt = true
    },
    loadBank () {
      this.opptionBank = [{ value: '', text: 'โปรดเลือกธนาคาร' }]
      data_bank.RECORDS.forEach(data => {
        this.opptionBank.push({ value: data.code, text: data.name })
      })
    },
    loadSystemAccount () {
      this.$store.dispatch('setting/getAccount').then(res => {
        res.data.items.forEach((data, index) => {
          const dt = data_bank.RECORDS.filter(x => x.code === data.bankName)
          if (index === 0 && this.type === 'add') {
            this.inputsPay.datasBank.value = data._id
          }
          this.optionAccount.push({ value: data._id, text: dt[0].name, ...data })
        })
      })
    },
    onClickCommitAccount () {
      const inputs = {
        id: this.userID,
        name: this.inputAddBank.name,
        bankNumber: this.inputAddBank.branchCode,
        bankName: this.inputAddBank.branchName,
        bankDetail: ''
      }
      this.$store.dispatch('customer/addBankAccount', inputs).then(res => {
        if (res.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              icon: 'AlertCircleIcon',
              variant: 'success',
              text: res.data.message
            }
          })
          this.onLoadOrderId()
          this.IsopenModalAddAccount = false
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: res.data.message
            }
          })
        }
      })
    },
    async onLoadOrderId () {
      const resOrder = await this.$store.dispatch('order/getOrderIDPay', { id: this.idOrder })
      if (resOrder.data.items.length > 0) {
        this.dataOrder = resOrder.data.items
        this.userID = this.dataOrder[0].customerId._id
        this.sumPriceCartForfmat = new Intl.NumberFormat('th', { minimumFractionDigits: 2 }).format(parseFloat(this.dataOrder[0].totalPrice.$numberDecimal))
        this.opptionBankCustomer = [{
          value: '',
          text: 'โปรดเลือก',
          bankNumber: ''
        }]
        this.dataOrder[0].customerId.bankAccount.forEach(data => {
          this.opptionBankCustomer.push({ value: data._id, text: `${data.bankName} ${data.bankNumber} ${data.name}`, ...data })
        })
        if (this.dataOrder.status !== 'i' && this.dataOrder.status !== 'a') {
          this.$store.dispatch('order/gettransaction', { orderId: this.idOrder }).then(res => {
            if (res.data.item) {
              this.dataPayment = res.data.item
              if (this.dataPayment.bankAccount) {
                const dtBankCust = this.opptionBankCustomer.filter(x => x.bankNumber === this.dataPayment.bankAccount.bankNumber && x.bankName === this.dataPayment.bankAccount.bankName)
                if (dtBankCust.length > 0) {
                  this.inputsPay.idBank = dtBankCust[0].value
                }
              }
              this.inputsPay.datasBank.value = this.dataPayment.bankaccountId._id
              this.inputsPay.amount = ''
              this.inputsPay.typePay = this.dataPayment.type
              this.inputsPay.pic = this.dataPayment.pic
            }
          })
        }
      }
    }
  },
  created () {
    this.loader()
  },
  data () {
    return {
      inIt: false,
      idOrder: this.$route.params.code,
      type: this.$route.params.type,
      IsopenModalAddAccount: false,
      dataOrder: [],
      dataPayment: null,
      optionAccount: [],
      opptionBank: [],
      opptionBankCustomer: [],
      sumPriceCartForfmat: '0.00',
      userID: '',
      inputAddBank: {
        branchCode: '',
        branchName: '',
        name: ''
      },
      inputsPay: {
        datasBank: {
          value: '',
          name: '',
          bankNumber: '',
          bankName: ''
        },
        amount: '',
        typePay: 'm',
        idBank: '',
        pic: '',
        file: null,
        checkpic: false
      },
      typePay: [{
        value: 'm',
        text: 'ออนไลน์แบ้งก์กิ้ง'
      }, {
        value: 'r',
        text: 'ชำระเงินสด'
      }, {
        value: 'c',
        text: 'เก็บเงินปลายทาง'
      }, {
        value: 'd',
        text: 'ตู้ฝากเงินสด'
      }, {
        value: 't',
        text: 'โอนผ่านตู้ATM'
      }]

    }
  }

})
</script>
<style scoped>
.card-adaresscommit {
  padding: 1.5rem;
  border: 3px dashed #77ad82;
  min-height: 210px;
}
.card-adaress {
  padding: 1.5rem;
  border: 3px dashed #b8c2cc;
}
.shadow-item {
  box-shadow: 1px -1px 5px -1px rgba(108, 108, 108, 0.6) !important;
}
</style>
<style>
.modal-title {
  color: white;
}
.fromlabel label {
  margin-right: 15px;
}
</style>
